import React, { useState, useEffect } from "react";
import "./Loader.css"; // Import your CSS file for styling

const Loader = ({ isLoading }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const images = [
    "./loader/bhoju_loader_01.png",
    "./loader/bhoju_loader_02.png",
    "./loader/bhoju_loader_03.png",
    "./loader/bhoju_loader_04.png",
    "./loader/bhoju_loader_05.png",
    "./loader/bhoju_loader_06.png",
    "./loader/bhoju_loader_07.png",
    "./loader/bhoju_loader_08.png",
    "./loader/bhoju_loader_09.png",
    "./loader/bhoju_loader_10.png",
    "./loader/bhoju_loader_11.png",
    "./loader/bhoju_loader_12.png",
    "./loader/bhoju_loader_13.png",
    "./loader/bhoju_loader_14.png",
    "./loader/bhoju_loader_15.png",
    "./loader/bhoju_loader_16.png",
    "./loader/bhoju_loader_17.png",
    "./loader/bhoju_loader_18.png",
    "./loader/bhoju_loader_19.png",
    "./loader/bhoju_loader_20.png",
    "./loader/bhoju_loader_21.png",
    "./loader/bhoju_loader_22.png",
    "./loader/bhoju_loader_23.png",
    "./loader/bhoju_loader_24.png",
    "./loader/bhoju_loader_25.png",
  ]; // List of PNG images

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 100); // Change image every 300ms (adjust as needed)
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isLoading, images.length]);

  return (
    <div className="loader-container">
      {isLoading && (
        // <div class="loader-1"></div>
        <img
          src={images[imageIndex]}
          alt="Loading..."
          className="loader-image"
        />
      )}
    </div>
  );
};

export default Loader;

// import "./Cookies.css";
import React, { useEffect, useState } from "react";
import "./AppSettng.css";
// import ListGroup from "react-bootstrap/ListGroup";
import Accordion from "react-bootstrap/Accordion";
import UserImage from "../common/UserImage";
// import { Button } from "react-bootstrap";
import { RiLogoutBoxFill, RiMovie2Fill, RiSearch2Fill } from "react-icons/ri";
import { FaHeadset, FaLocationDot, FaAngleRight } from "react-icons/fa6";
import { HiDocumentText } from "react-icons/hi";
import { FaShieldAlt, FaUser } from "react-icons/fa";
import { Card, Button, ListGroup, CardBody } from "react-bootstrap";
import { Link } from "react-router-dom";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { APP_COLORS, APP_IMAGES } from "../common/Api";

const AppSettingPage = (props) => {
  const listGroupItem = {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
  };
  const linkStyle = {
    textDecoration: "none", // Remove underline
    color: "inherit", // Inherit text color
  };
  const [isSubscribed, setiIsSubscribed] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [imageSrc, setImageSrc] = useState(
    "https://adminpanelconnect.olaple.com/placeholder.png"
  );
  const [user, setUser] = useState(null);
  const handleImageChange = (newImage) => {
    setImageSrc(newImage);
  };

  const logoutFunc = () => {
    try {
      localStorage.removeItem("OlapleAppUser");
      // setIsUserRegistered(false);
      //console.log("LOGOUT FUNCTION CALLED");
      googleLogout();
    } catch (error) {
      console.error("Error occurred during logout:", error);
    }
  };

  useEffect(() => {
    //console.log("props.userData;;;", props);
    if (props.userData != null) {
      //console.log("props.userData;;;", props.userData);
      setUser(props.userData);
      setImageSrc(props.userData.picture);
      setiIsSubscribed(props.userData.isSubscribed);
      setIsLoggedIn(true);
      // picture
      // fullName
      // username
    }
  }, []);

  //
  return (
    <div
      style={{
        width: "100%",
        minHeight: "65rem",
        position: "relative",
        backgroundImage: `url(${APP_IMAGES.APP_MAIN_BG_MOB})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

        backgroundBlendMode: "overlay",
      }}
    >
      <Card
        style={{
          backgroundColor: "transparent",
          padding: "10%",
          height: "100vh",
        }}
      >
        <Card.Header
          style={{
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          {user && (
            <img
              src={imageSrc}
              alt="Profile Icon"
              style={{
                height: "100px",
                width: "100px",
                borderRadius: "50%",
                marginBottom: "1rem", // Adjust margin bottom as needed
              }}
            />
          )}
          <h6
            style={{
              textTransform: "capitalize",
              color: "#008dc4",
              fontWeight: "600",
            }}
          >
            {user && user.fullName}
          </h6>
          {user && user.login_by === "google" && (
            <h6 style={{ color: "#008dc4", fontWeight: "600" }}>
              {user.email}
            </h6>
          )}
          {user && user.login_by === "manual" && (
            <h6 style={{ color: "#008dc4", fontWeight: "600" }}>
              {user.username}
            </h6>
          )}
          {/* <h6 className="text-white">{user && user.email}</h6> */}
          {user == null && (
            <Button
              variant="primary"
              style={{
                color: APP_COLORS.APP_NAV_COLOR,
                backgroundColor: APP_COLORS.APP_DARK_1,
                width: "100%", // Make button width 100% of the container
                border: "none",
                borderRadius: "20px",
                height: "50px",
                paddingTop: "12px",
                fontWeight: "600",
              }}
              as={Link}
              to="/login"
            >
              Login / Register
            </Button>
          )}
          &nbsp; &nbsp;
          {isSubscribed != null && !isSubscribed && (
            <Button
              variant="primary"
              style={{
                backgroundColor: APP_COLORS.APP_DARK_1,
                width: "100%", // Make button width 100% of the container
                border: "none",
                borderRadius: "20px",
                height: "50px",
                paddingTop: "12px",
                fontWeight: "600",
                color: APP_COLORS.APP_NAV_COLOR,
              }}
              as={Link}
              to="/subscribe"
            >
              SUBSCRIBE
            </Button>
          )}
        </Card.Header>
        <div>
          <Card
            body
            style={{
              backgroundColor: APP_COLORS.APP_DARK_1,
              // backgroundColor: "#008dc4",
              borderRadius: "12px",

              //   element.style {
              //     background-color: #008dc4;
              //     border-radius: 12px;
              // }
            }}
          >
            {" "}
            <Link to="/support" style={linkStyle}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: APP_COLORS.APP_NAV_COLOR,
                  fontWeight: "600",
                }}
              >
                <FaHeadset />
                <span>&nbsp; &nbsp; Support / Help</span>
                <FaAngleRight style={{ marginLeft: "auto" }} />
              </div>
            </Link>
            <hr style={{ color: APP_COLORS.APP_NAV_COLOR }} />
            <Link to="/subscribe" style={linkStyle}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: APP_COLORS.APP_NAV_COLOR,
                  fontWeight: "600",
                }}
              >
                <RiMovie2Fill />
                <span>&nbsp; &nbsp; Subscription</span>
                <FaAngleRight style={{ marginLeft: "auto" }} />
              </div>
            </Link>
            <hr style={{ color: APP_COLORS.APP_NAV_COLOR }} />
            <Link to="/terms-and-conditions" style={linkStyle}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: APP_COLORS.APP_NAV_COLOR,
                  fontWeight: "600",
                }}
              >
                <HiDocumentText />
                <span>&nbsp; &nbsp; Terms & Conditions</span>
                <FaAngleRight style={{ marginLeft: "auto" }} />
              </div>
            </Link>
            <hr style={{ color: APP_COLORS.APP_NAV_COLOR }} />
            <Link to={isLoggedIn ? "/location" : "/login"} style={linkStyle}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: APP_COLORS.APP_NAV_COLOR,
                  fontWeight: "600",
                }}
              >
                <FaLocationDot />
                <span>&nbsp; &nbsp; Location</span>
                <FaAngleRight style={{ marginLeft: "auto" }} />
              </div>
            </Link>
            <hr style={{ color: APP_COLORS.APP_NAV_COLOR }} />
            <Link to="/privacy" style={linkStyle}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: APP_COLORS.APP_NAV_COLOR,
                  fontWeight: "600",
                }}
              >
                <FaShieldAlt />
                <span>&nbsp; &nbsp; Privacy Policy</span>
                <FaAngleRight style={{ marginLeft: "auto" }} />
              </div>
            </Link>
            <hr style={{ color: APP_COLORS.APP_NAV_COLOR }} />
            <Link to="/about-us" style={linkStyle}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: APP_COLORS.APP_NAV_COLOR,
                  fontWeight: "600",
                }}
              >
                <FaUser />
                <span>&nbsp; &nbsp; About Us</span>
                <FaAngleRight style={{ marginLeft: "auto" }} />
              </div>
            </Link>
          </Card>
        </div>

        {/* <ListGroup variant="flush" style={{ backgroundColor: "#1C1C1E" }}>
          <Link to="/support" style={linkStyle}>
            <ListGroup.Item style={listGroupItem}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaHeadset />
                <span>&nbsp; &nbsp; Support / Help</span>
              </div>
              <FaAngleRight />
            </ListGroup.Item>
          </Link>
          <Link to="/location" style={linkStyle}>
            <ListGroup.Item style={listGroupItem}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaLocationDot />
                <span>&nbsp; &nbsp; Location</span>
              </div>
              <FaAngleRight />
            </ListGroup.Item>
          </Link>
          <Link to="/terms-and-conditions" style={linkStyle}>
            <ListGroup.Item style={listGroupItem}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <HiDocumentText />
                <span>&nbsp; &nbsp; Terms & Conditions</span>
              </div>
              <FaAngleRight />
            </ListGroup.Item>
          </Link>

          <Link to="/privacy" style={linkStyle}>
            <ListGroup.Item style={listGroupItem}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaShieldAlt />
                <span>&nbsp; &nbsp; Privacy Policy</span>
              </div>
              <FaAngleRight />
            </ListGroup.Item>
          </Link>

          <Accordion style={{ height: "100%" }}>
            <Accordion.Item
              eventKey="0"
              style={{
                backgroundColor: "transparent",

                border: "none",
              }}
            >
              <Accordion.Header
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <RiSearch2Fill />
                  <span>&nbsp; &nbsp; Other</span>
                </div>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "rgb(66,66,66)" }}>
                <ul className="custom-bullet">
                  <li className="nav-item">
                    <a className="nav-link" href="/subscribe">
                      Subscribe
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/about-us">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/contact-us">
                      Contact Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/CookiePolicy">
                      Cookie Policy
                    </a>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </ListGroup> */}
      </Card>
    </div>
  );
};

export default AppSettingPage;
{
  /*
    // <div className="container-fluid privacyPolicyImgbox">
    //   <div
    //     className="container privacyPolicyBellowTextBox"
    //     style={{ height: "100%" }}
    //   >
    //     <div id="scroll" style={{ height: "100%" }}>
    //       {/* <Accordion style={{ height: "100%" }}>
    //         <Accordion.Item
    //           eventKey="0"
    //           style={{
    //             backgroundColor: "transparent",
    //             borderColor: "transparent",
    //           }}
    //         >
    //           <Accordion.Header>EXPLORE</Accordion.Header>
              // <Accordion.Body style={{ backgroundColor: "rgb(66,66,66)" }}>
              //   <ul className="custom-bullet">
              //     <li className="nav-item">
              //       <a className="nav-link" href="/home">
              //         Home
              //       </a>
              //     </li>
              //     <li className="nav-item">
              //       <a className="nav-link" href="/browse">
              //         Browse
              //       </a>
              //     </li>
              //     <li className="nav-item">
              //       <a className="nav-link" href="/subscribe">
              //         Subscribe
              //       </a>
              //     </li>
              //   </ul>
              // </Accordion.Body>
    //         </Accordion.Item>
    //         <Accordion.Item
    //           eventKey="2"
    //           style={{
    //             backgroundColor: "transparent",
    //             borderColor: "transparent",
    //           }}
    //         >
    //           <Accordion.Header>QUICK LINKS</Accordion.Header>
    //           <Accordion.Body style={{ backgroundColor: "rgb(66,66,66)" }}>
                // <ul className="custom-bullet">
                //   <li className="nav-item">
                //     <a className="nav-link" href="/about-us">
                //       About Us
                //     </a>
                //   </li>
                //   <li className="nav-item">
                //     <a className="nav-link" href="/contact-us">
                //       Contact Us
                //     </a>
                //   </li>
                //   <li className="nav-item">
                //     <a className="nav-link" href="/support">
                //       Support
                //     </a>
                //   </li>
                //   <li className="nav-item">
                //     <a className="nav-link" href="/CookiePolicy">
                //       Cookie Policy
                //     </a>
                //   </li>
                // </ul>
    //           </Accordion.Body>
    //         </Accordion.Item>
    //         <Accordion.Item
    //           eventKey="1"
    //           style={{
    //             backgroundColor: "transparent",
    //             borderColor: "transparent",
    //           }}
    //         >
    //           <Accordion.Header>LEGAL</Accordion.Header>
    //           <Accordion.Body style={{ backgroundColor: "rgb(66,66,66)" }}>
    //             <ul className="custom-bullet">
    //               <li className="nav-item">
    //                 <a className="nav-link" href="/privacy">
    //                   Privacy Policy
    //                 </a>
    //               </li>
    //               <li className="nav-item">
    //                 <a className="nav-link" href="/terms-and-conditions">
    //                   Terms &amp; Conditions
    //                 </a>
    //               </li>
    //               <li className="nav-item">
    //                 <a className="nav-link" href="/refund-policy">
    //                   Refund Policy
    //                 </a>
    //               </li>
    //             </ul>
    //           </Accordion.Body>
    //         </Accordion.Item>
    //       </Accordion> 
    //     </div>
    //   </div>
    // </div>
  */
}
